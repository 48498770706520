$blue: #5ec2d6;
$blue-rgba: rgb(94, 194, 214, 1);
$purple: #B84794;
$purple-rgba: rgb(184, 71, 148, 1);
$dark-blue: #1D1637;
$dark-blue-rgba: rgb(29, 22, 55, 1);

.gr-color {
    background:
        linear-gradient(to right, $dark-blue, rgb(29, 22, 55, 0)),
        linear-gradient(to top, $purple, $blue);
    ;
    height: 10vh;
    min-height: 30px;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
}

.f-left {
    float: left;
    height: 100%;
    max-width: 35%;
    background-size: contain;
    display: flex;
}

.image {
    width: 80%;
    height: 65%;
    flex: 1;
    align-self: center;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 5%;
}

.btnlink {
    background-color: transparent;
    border-radius: 9999em 9999em 9999em 9999em;
    margin-left: 1rem;
    appearance: button;
    text-align: center;
    line-height: 2.5rem;
    box-sizing: content-box;
    flex: 1;
    cursor: pointer;
    border: none;
    color: white;
    font-family: 'ara hamah 1982', Regular;
    align-self: center;
}

@media screen {
    .btnlink {
        width: 13%;
        // height: 50%;
        font-size: clamp(0.75rem, 2vw, 2rem);
        margin-right: 5px;
    }
}


.btnlink:hover,
.btnlink:focus {
    background-image: linear-gradient(to left, $purple, $blue);
    border: none;
}

.btnlink:active {
    background-image: linear-gradient(to left, $purple, $blue);
    border: none;
}

.div-right {
    float: right;
    top: 50%;
    height: 100%;
    width: 50%;
    margin: 0 auto;
    display: flex;
    margin-right: 1rem;
}