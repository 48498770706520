.iimage {
    padding: 10%;
}

.btnn {
    border-radius: 9999em 9999em 9999em 9999em;
    margin-right: 15px;
    cursor: pointer;
    width: 50px;
    background-color: transparent;
    border: 1px solid black;
}

.txt {
    font-family: 'ara hamah 1982', Regular;
    font-size: 1rem;
    justify-self: center;
    align-self: center;
}