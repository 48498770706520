.links {
    font-family: 'ara hamah 1982', Regular;
    color: white;
    font-size: xx-large;
    cursor: pointer;
}

.main-div {
    background-color: '#1D1637';
    justify-content: 'center';
    width: '100vw';
    height: '10%';
    display: 'flex';
    flex-direction: 'column';
    padding: '2rem'
}

.copyright{
    font-family: 'ara hamah 1982', Regular;
    color: white;
}